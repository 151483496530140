import React from 'react';
import { NavLink } from 'react-router-dom';
import MyAssets from '../../assets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import $ from 'jquery';
import { isMobileView } from '../../misc/helper_functions';
import { _loading } from '../../functions/message_functions';
import { _createFormData, _getLocalStorage, _formatDateCheckInOut, _formatDecimalAmount, _prepareFloatAmount, _formatDate, _formatDatetime } from '../../functions/helper_functions';
import axios from 'axios';

import DOMPurify from 'dompurify';

import * as apis from '../../const/apis';
import * as config from '../../const/config';

var lastHeight = 0;
var lastOffSet = 0;

class Main extends React.Component{
    constructor(props){
        super(props);

        let tempModifyData = _getLocalStorage(config.KEY_TEMP_MODIFY_BOOKING_DATA);

        if ( tempModifyData ) {
            tempModifyData = tempModifyData.split(";");

            localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, tempModifyData[0]);
            localStorage.setItem(config.KEY_UPDATE_TOKEN, tempModifyData[1]);
        }

        let totalRateAndPackages = _getLocalStorage(config.KEY_TOTAL_ROOM_RATE_AND_PACKAGES);
        let totalTaxesAndFees = _getLocalStorage(config.KEY_TOTAL_TAXES_AND_FEES);
        let addons = _getLocalStorage(config.KEY_ADDONS);
        let totalAddonsAmount = _getLocalStorage(config.KEY_TOTAL_PACKAGES_AMOUNT);
        let totalAddonsTax = _getLocalStorage(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES);
        let selectedAddons = _getLocalStorage(config.KEY_SELECTED_ADDON);
        let totalRooms = _getLocalStorage(config.KEY_TOTAL_ROOMS);
        let grandTotal = _getLocalStorage(config.KEY_GRAND_TOTAL);
        let totalAdult = _getLocalStorage(config.KEY_TOTAL_ADULT);
        let totalChildren = _getLocalStorage(config.KEY_TOTAL_CHILDREN);
        let specialCodeType = _getLocalStorage(config.KEY_SPECIAL_CODE_TYPE);
        let specialCode = _getLocalStorage(config.KEY_SPECIAL_CODE);
        let isLongTermCode = specialCodeType == 'Long Term Code';
        let addonCharges = _getLocalStorage(config.KEY_ADDON_CHARGES);
        let inclusivePackages = _getLocalStorage(config.KEY_INCLUSIVE_PACKAGE);
        let addonQty = [];
        let isShowAddonBreakdown = [];
        let summaryAddonsCharges = [];
        let hasInclusivePackage = false;
        let updateToken = _getLocalStorage(config.KEY_UPDATE_TOKEN);
        let modifyBookingID = _getLocalStorage(config.KEY_UPDATE_BOOKING_ID);
        let gst = _getLocalStorage(config.KEY_GST_PERCENTAGE);
        gst = !isNaN(gst) ? gst : config.CURRENT_GST;

        if ( totalRateAndPackages === null || totalTaxesAndFees === null || grandTotal === null || totalRooms === null ) {
  			window.location.href = config.WEBSITE_URL;
  		}
        else {
            if ( totalRateAndPackages ) { totalRateAndPackages = parseFloat(totalRateAndPackages.replace(/,/, "")); }
            if ( totalTaxesAndFees ) { totalTaxesAndFees = parseFloat(totalTaxesAndFees.replace(/,/, "")); }
            if ( addons ) { addons = JSON.parse(addons); }
            if ( selectedAddons ) { selectedAddons = JSON.parse(selectedAddons); }

            if ( ! (addons && Object.keys(addons).length > 0) ) {
                this.props.history.push('/');
            }

            for ( let index = 0; index < addons.length; index++ ) {
                summaryAddonsCharges[addons[index].id] = {};
                summaryAddonsCharges[addons[index].id].title = addons[index].title;
                summaryAddonsCharges[addons[index].id].tax = 0;
                summaryAddonsCharges[addons[index].id].amount = 0;
            }

            if ( addonCharges ) {
                addonCharges = JSON.parse(addonCharges);

                for ( let index = 0; index < addonCharges.length; index++ ) {
                    if ( addonCharges[index] ) {
                        summaryAddonsCharges[addonCharges[index].id].amount = addonCharges[index].amount;
                        summaryAddonsCharges[addonCharges[index].id].tax = addonCharges[index].tax;
                    }
                }
            }

            // Initial qty for add-ons
            for ( let index in addons ) {
                let addon = addons[index];
                isShowAddonBreakdown[addon.id] = false;

                if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
                    addonQty[addon.id + "_" + addon.title] = 1;
                }
                else if ( addon.type == config.ADDON_TYPE_DAILY ) {
                    for ( let addonDate in addon.packages ) {
                        addonQty[addon.id + "_" + addon.title + "_" + addonDate] = 1;
                    }
                }
            }

            if ( selectedAddons ) {
                for ( let index = 0; index < selectedAddons.length; index++ ) {
                    let selectedAddon = selectedAddons[index];
                    let selectedAddonData = selectedAddon.split("_");

                    // For Stay Period
                    let qty = 1;
                    if ( selectedAddonData.length == 3 ) {
                        qty = parseInt(selectedAddonData[2]);

                        addonQty[selectedAddonData[0] + "_" + selectedAddonData[1]] = qty;
                    }
                    // For Daily Selection
                    else if ( selectedAddonData.length == 4 ) {
                        qty = parseInt(selectedAddonData[3]);

                        addonQty[selectedAddonData[0] + "_" + selectedAddonData[1] + "_" + selectedAddonData[2]] = qty;
                    }
                }
            }

            let inclusivePackagesDisplay = '';
            if ( inclusivePackages && inclusivePackages !== '[]' && inclusivePackages.length > 0 ) {
                hasInclusivePackage = true;
                inclusivePackages = JSON.parse(inclusivePackages);

                for ( let index in inclusivePackages ) {
                    inclusivePackagesDisplay += "<br />" + inclusivePackages[index].name;
                }

                inclusivePackagesDisplay = inclusivePackagesDisplay.substring("<br />".length);
            }

            grandTotal = parseFloat(grandTotal.toString().replace(/,/g, ''));
            totalRateAndPackages = parseFloat(totalRateAndPackages.toString().replace(/,/g, ''));
            totalTaxesAndFees = parseFloat(totalTaxesAndFees.toString().replace(/,/g, ''));

            this.state={
                checkInDate: _getLocalStorage(config.KEY_CHECK_IN_DATE),
                checkOutDate: _getLocalStorage(config.KEY_CHECK_OUT_DATE),
                totalNight: _getLocalStorage(config.KEY_TOTAL_NIGHT),
                roomCharge: isLongTermCode ? 0 : (totalRateAndPackages),
                chargeBreakdown: _getLocalStorage(config.KEY_CHARGE_BREAKDOWN),
                tax: isLongTermCode ? 0 : (totalTaxesAndFees),
                grandTotal: isLongTermCode ? 0 : (grandTotal),
                roomName: _getLocalStorage(config.KEY_ROOM_NAME),
                totalRooms: parseInt(totalRooms),
                addons: addons,
                totalAddonsAmount: totalAddonsAmount !== null ? parseFloat(totalAddonsAmount) : 0,
                totalAddonsTax: totalAddonsTax !== null ? parseFloat(totalAddonsTax) : 0,
                selectedAddons: selectedAddons !== null ? (selectedAddons) : [],
                addonQty: addonQty !== null ? addonQty : [],
    		    isShowChargeBreakdown: false,
                isShowAddonBreakdown: isShowAddonBreakdown,
                totalAdult: totalAdult,
                totalChildren: totalChildren !== null ? totalChildren : 0,
                specialCodeType: specialCodeType !== null ? specialCodeType : null,
                specialCode: specialCode !== null ? specialCode : null,
                summaryAddonsCharges: summaryAddonsCharges,
                inclusivePackages: inclusivePackages !== null ? inclusivePackages : null,
                inclusivePackagesDisplay: inclusivePackagesDisplay !== null ? inclusivePackagesDisplay : null,
                addonChargeBreakdown: {},
                isLoading: false,
                hasInclusivePackage: hasInclusivePackage,
                updateToken: updateToken,
                modifyBookingID: modifyBookingID,
                isLongTermCode: isLongTermCode,
                gst: gst
            }

            // console.log(this.state);
        }

        this.handleAddonDaily = this.handleAddonDaily.bind(this);
        this.handleAddonStayPeriod = this.handleAddonStayPeriod.bind(this);
        this.next = this.next.bind(this);
        this.handleAddonQty = this.handleAddonQty.bind(this);
        this.handleCancelModifying = this.handleCancelModifying.bind(this);
    }

    componentDidMount() {
        document.addEventListener('scroll',this.handleScroll);
        window.scrollTo(0, 0);
        $("#login").hide();

        let addons = this.state.addons;
        // loop to check is mandatory add on and set the initial value
        if ( addons ) {
            for ( let addonIndex in addons ) {
                let addon = addons[addonIndex];

                if ( addon.is_mandatory ) {
                    if ( addon.type == config.ADDON_TYPE_DAILY ) {
                        for ( let addonDate in addon.packages ) {
                            this.processAddonDaily(addon, addon.id + "_" + addon.title + '_' + addonDate, true);
                        }
                    }
                    else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
                        this.processAddonStayPeriod(addon, addon.id + "_" + addon.title, true);
                    }
                }
            }

            this.setAddOnChargeBreakdown();
        }
  	}

  	componentWillUnmount(){
		document.removeEventListener('scroll',this.handleScroll);
  	}

    next = () => {
        let summaryAddonsCharges = [];
        for ( let id in this.state.summaryAddonsCharges ) {
            let summaryAddonCharge = {};
            summaryAddonCharge.id = id;
            summaryAddonCharge.title = this.state.summaryAddonsCharges[id].title;
            summaryAddonCharge.amount = this.state.summaryAddonsCharges[id].amount;
            summaryAddonCharge.tax = this.state.summaryAddonsCharges[id].tax;

            summaryAddonsCharges.push(summaryAddonCharge);
        }

        // Capture all the add-ons and add into grand total
        localStorage.setItem(config.KEY_ADDONS, JSON.stringify(this.state.addons));
        localStorage.setItem(config.KEY_TOTAL_PACKAGES_AMOUNT, this.state.totalAddonsAmount);
        localStorage.setItem(config.KEY_TOTAL_PACKAGE_TAX_AND_FEES, this.state.totalAddonsTax);
        localStorage.setItem(config.KEY_SELECTED_ADDON, JSON.stringify(this.state.selectedAddons));
        localStorage.setItem(config.KEY_ADDON_CHARGES, JSON.stringify(summaryAddonsCharges));
        localStorage.setItem(config.KEY_ADDON_CHARGES_BREAKDOWN, JSON.stringify(this.state.addonChargeBreakdown));
        //localStorage.setItem(config.KEY_ADDON_QTY, JSON.stringify(this.state.addonQty));

        //window.location.href = config.WEBSITE_URL + "book";
        this.props.history.push("/book");
    }

    handleModifyBack = () => {
        let tempModifyData = _getLocalStorage(config.KEY_TEMP_MODIFY_BOOKING_DATA);

        if ( tempModifyData ) {
            tempModifyData = tempModifyData.split(";");

            localStorage.setItem(config.KEY_UPDATE_BOOKING_ID, tempModifyData[0]);
            localStorage.setItem(config.KEY_UPDATE_TOKEN, tempModifyData[1]);
        }
    }

    handleScroll = (event) => {
        if(!isMobileView()){
            let target = 'reservation';
            let footerTopOffset = $('#footerPart').offset().top;
            let { offsetHeight } = document.getElementById(target);
            let bottomLimit = window.pageYOffset + offsetHeight + 50;
            if(!lastHeight && !lastOffSet){
                            lastHeight = offsetHeight;
                lastOffSet = $(`#${target}`).offset().top;

            }

            let widthTarget = $(`#${target}`).outerWidth();
            if(window.pageYOffset < (lastOffSet)){
                $(`#${target}`).css({
                    position:'relative',
                    width:'',
                });
            }else if((window.pageYOffset > lastOffSet) && (bottomLimit<footerTopOffset)){
                $(`#${target}`).css({
                    position:'fixed',
                    top:0,
                    width:widthTarget
                });
            }else{
                $(`#${target}`).css({
                    position:'relative',
                    width:'',
                    top:footerTopOffset - lastOffSet - offsetHeight - 25
                });
            }
        }
    }

    handleCancelModifying() {
        localStorage.removeItem(config.KEY_UPDATE_TOKEN);
        localStorage.removeItem(config.KEY_UPDATE_BOOKING_ID);

        //this.props.history.push('/manageBooking');
        window.location.href = config.WEBSITE_URL;
    }

    handleShowHide = (target) => {
        let current = this.state[target];
        current ? $(`.${target}`).slideDown() : $(`.${target}`).slideUp();
        let icon = $(`.${target}-icon > svg`);
        icon.animate({borderSpacing: current?0:180},{
            step: function(now,fx){
                $(this).css('-webkit-transform', 'rotate(' + now + 'deg)');
                $(this).css('-moz-transform', 'rotate(' + now + 'deg)');
                $(this).css('transform', 'rotate(' + now + 'deg)');
            },
            duration:'slow',
        },'linear')
        this.setState({[target]:!current});
    }
    setUpReservationPart = () => {
  		const viewStatus = isMobileView();
  		if(viewStatus){
  			return(
  				<div className="modal fade" id="reservation-modal" tabIndex="-1" role="dialog" aria-hidden="true">
  					<div className="modal-dialog" role="document">
  						<div className="modal-content">
  							<div className="modal-body">
  								{this.reservationBodyPart(viewStatus)}
  							</div>
  						</div>
  					</div>
  				</div>
  			)
  		}else{
  			return this.reservationBodyPart(viewStatus);
  		}
    }

    reservationBodyPart = (status) => {
  		return(
  			<div id={`${status?'':'reservation'}`}>
  				<div className="show-mobile" id="btn-modal-close" data-izimodal-close="" data-izimodal-transitionout="bounceOutDown">
  					<em className="fas fa-times" data-toggle="tooltip" data-placement="top" title="" data-remove-id="1" data-dismiss="modal" data-original-title="Remove"></em>
  				</div>
  				<h3>Reservation Summary</h3>
  				<div className="d-inline-block">
            <p className="strong text-green">{ this.state.roomName }</p>

  					<p className="strong">Check In</p>
  					<p className="">{ _formatDateCheckInOut(this.state.checkInDate) }</p>

      			<p className="strong">Check Out</p>
  					<p className="">{ _formatDateCheckInOut(this.state.checkOutDate) }</p>

            <p className="strong">Reservation</p>
  					<p className="">{this.state.totalRooms} Room{ this.state.totalRooms > 1 ? 's' : '' }, {this.state.totalAdult} Adult{ this.state.totalAdult > 1 ? 's' : '' }{ this.state.totalChildren > 0 ? <span>, { this.state.totalChildren } { this.state.totalChildren > 1 ? 'Children' : 'Child' }</span>  : null }, { this.state.totalNight } Night{ this.state.totalNight > 1 ? 's' : '' }</p>

            { this.state.hasInclusivePackage ? <p className="strong">Inclusive Of</p> : null }
            { this.state.hasInclusivePackage ? <p className="" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.state.inclusivePackagesDisplay) }}></p> : null }

            { this.state.specialCodeType && this.state.specialCode ? <p className="strong">Special Code</p> : null }
            { this.state.specialCodeType && this.state.specialCode ? <p className="">{this.state.specialCodeType}, {this.state.specialCode}</p> : null }
  				</div>

  				<hr className="mmt-0" />

  				<div id="grand-total">
            <p className="strong pointer link" onClick={() => { this.setState({ isShowChargeBreakdown: !this.state.isShowChargeBreakdown }) }}>
                Room Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.roomCharge) }</span>
                <FontAwesomeIcon className={ !this.state.isShowChargeBreakdown ? "rotate-180" : ""} icon="chevron-up"/>
            </p>
            { this.state.isShowChargeBreakdown ? <table className="table-charge-breakdown"><tbody>{this.loopChargeBreakdown()}</tbody></table> : null }

            { Object.keys(this.state.addonChargeBreakdown).length > 0 ?
							<p>Add-on:</p>
						: null }
            { this.loopAddonsCharges() }
            {/* this.state.totalAddonsAmount > 0 ? <p className="strong">Add-ons Charges <span className="float-right">S$ { _formatDecimalAmount(this.state.totalAddonsAmount) }</span></p> : null */}
  					<p className="strong">Tax <span className="float-right">S$ { _formatDecimalAmount(this.state.tax + this.state.totalAddonsTax) }</span></p>
  					<p className="strong">Grand Total<span className="float-right">S$ { _formatDecimalAmount(this.state.grandTotal + this.state.totalAddonsAmount + this.state.totalAddonsTax) }</span></p>
  				</div>

  				<button className="btn-md btn btn-success btn-square mt-4" id="checkout" onClick={ this.next }>
  					   NEXT
  				</button>
  				{/*<div id="agreement" className="mt-4">
  					<p>* This reservation is subject to the Terms & Conditions found <NavLink to="#">here</NavLink>.</p>
  					<p>* The personal data you provide will be used, disclosed and (where appropriate) transferred overseas in accordance with our Privacy Policy and Cookies Policy found <NavLink to="#">here</NavLink>.</p>
  					<p>* In order to proceed with this reservation, please indicate your agreement to the Terms & Conditions and the <NavLink to="#">Privacy policy</NavLink> by clicking above.</p>
  				</div>*/}
  			</div>
  		)
  	}

    loopChargeBreakdown() {
        let dataToReturn = [];
        let charges = JSON.parse(this.state.chargeBreakdown);

        for ( let chargeDate in charges ) {
                dataToReturn.push(
                        <tr key={chargeDate}>
                                <td width="60%">{ _formatDate(chargeDate) }</td>
                                <td width="40%" className="text-right">S$ { this.state.isLongTermCode ? "0.00" : charges[chargeDate] }</td>
                        </tr>
                );
        }

        return dataToReturn;
    }

    loopAddonsCharges() {
        let summaryAddonsCharges = this.state.summaryAddonsCharges;
        let addonChargeBreakdown = this.state.addonChargeBreakdown;
        let dataToReturn = [];

        for ( let id in summaryAddonsCharges ) {
            if ( addonChargeBreakdown[summaryAddonsCharges[id].title] ) {
                dataToReturn.push(
                    <div id="addon" key={summaryAddonsCharges[id].title}>
                        <p className="strong pointer link addon-label" onClick={ this.showHideAddonChargeBreakdown.bind(this, id) }>
                            { summaryAddonsCharges[id].title }

                            <FontAwesomeIcon className={ !this.state.isShowAddonBreakdown[id] ? "rotate-180" : ""} icon="chevron-up"/>
                        </p>

                        <span className="addon-amount">S$ { _formatDecimalAmount(summaryAddonsCharges[id].amount.toFixed(2))  }</span>

                        { this.state.isShowAddonBreakdown[id] ? <table className="table-charge-breakdown"><tbody>{this.loopAddonChargeBreakdown(summaryAddonsCharges[id].title)}</tbody></table> : null }
                    </div>
                )
            }
        }

        return dataToReturn;
    }

    showHideAddonChargeBreakdown(id) {
        let isShowAddonBreakdown = this.state.isShowAddonBreakdown;
        isShowAddonBreakdown[id] = !isShowAddonBreakdown[id];

        this.setState({ isShowAddonBreakdown });
    }

    setAddOnChargeBreakdown() {
        let selectedAddons = arguments[0] ? arguments[0] : this.state.selectedAddons;
        let addons = arguments[1] ? arguments[1] : this.state.addons;
        let summaryAddonsCharges = arguments[2] ? arguments[2] : this.state.summaryAddonsCharges;

        let addonCharges = {};
        let totalAddonsAmount = 0, totalAddonsTax = 0;
        let addonForCalculation = [];

        for ( let index in summaryAddonsCharges ) {
            summaryAddonsCharges[index].amount = 0;
            summaryAddonsCharges[index].tax = 0;
        }
        for ( let index = 0; index < selectedAddons.length; index++ ) {
            // 0: id, 1: title, 2: date / qty, 3: qty
            let selectedAddonData = selectedAddons[index].split("_");

            if ( !addonCharges[selectedAddonData[1]] ) {
                addonCharges[selectedAddonData[1]] = {};
            }

            // Stay Period
            let addonCharge = {};
            if ( selectedAddonData.length == 3 ) {
                addonCharge = {
                    title: selectedAddonData[1],
                    qty: selectedAddonData[2],
                    date: 'All Nights'
                };

                // Stay Period, sum up all the amount and calculate with the qty
                let totalAmount = 0;
                let isFound = false;
                for ( let addonIndex = 0; addonIndex < addons.length && !isFound; addonIndex++ ) {
                    if ( addonCharge.title == addons[addonIndex].title ) {
                        for ( let addonDate in addons[addonIndex].packages ) {
                            totalAmount += parseFloat(addons[addonIndex].packages[addonDate].amount);

                            summaryAddonsCharges[selectedAddonData[0]].amount += parseFloat(addons[addonIndex].packages[addonDate].amount);
                            summaryAddonsCharges[selectedAddonData[0]].tax += parseFloat(addons[addonIndex].packages[addonDate].taxAmount);

                            totalAddonsAmount += parseFloat(addons[addonIndex].packages[addonDate].amount);
                            totalAddonsTax += parseFloat(addons[addonIndex].packages[addonDate].taxAmount);

                            console.log(addonDate);
                            let addonForCalculationDate = _formatDatetime(addonDate, 'YYYY-MM-DD');
                            console.log(addonForCalculationDate);
                            console.log("dang");
                            // addonForCalculationDate = addonForCalculationDate.getFullYear() + "-" + (addonForCalculationDate.getMonth() + 1) + "-" + addonForCalculationDate.getDate();
                            if ( addonForCalculation[addonForCalculationDate] === undefined ) {
                                addonForCalculation[addonForCalculationDate] = 0;
                            }
                            addonForCalculation[addonForCalculationDate] += parseFloat(addons[addonIndex].packages[addonDate].amount);
                        }

                        isFound = true;
                    }
                }

                addonCharge.totalAmount = totalAmount;
            }
            else if ( selectedAddonData.length == 4 ) {
                addonCharge = {
                    title: selectedAddonData[1],
                    qty: selectedAddonData[3],
                    date: selectedAddonData[2]
                };

                // Daily Selection, sum up the amount based on the date and calculate with the qty
                let isFound = false;
                for ( let addonIndex = 0; addonIndex < addons.length && !isFound; addonIndex++ ) {
                    if ( addonCharge.title == addons[addonIndex].title ) {
                        addonCharge.totalAmount = parseFloat(addons[addonIndex].packages[addonCharge.date].amount);

                        summaryAddonsCharges[selectedAddonData[0]].amount += addonCharge.totalAmount;
                        summaryAddonsCharges[selectedAddonData[0]].tax += parseFloat(addons[addonIndex].packages[addonCharge.date].taxAmount);

                        totalAddonsAmount += addonCharge.totalAmount;
                        totalAddonsTax += parseFloat(addons[addonIndex].packages[addonCharge.date].taxAmount);

                        isFound = true;

                        let addonForCalculationDate = _formatDatetime(addonCharge.date, 'YYYY-MM-DD');
                        if ( addonForCalculation[addonForCalculationDate] === undefined ) {
                            addonForCalculation[addonForCalculationDate] = 0;
                        }
                        addonForCalculation[addonForCalculationDate] += parseFloat(addons[addonIndex].packages[addonCharge.date].amount);
                    }
                }
            }

            addonCharges[selectedAddonData[1]][addonCharge.date] = addonCharge;
        }

        // recalculate taxes for both room and addons
        let totalTaxesCalculated = 0;

        // console.log("wubai " + this.state.gst);
        let chargeBreakdown = JSON.parse(this.state.chargeBreakdown);
        for ( let chargeDate in chargeBreakdown ) {
            let charge = _prepareFloatAmount(chargeBreakdown[chargeDate]);

            if ( addonForCalculation[chargeDate] !== undefined ) {
                let addonAmount = _prepareFloatAmount(addonForCalculation[chargeDate]);
                totalTaxesCalculated += parseFloat(((charge + addonAmount) * this.state.gst / 100).toFixed(2));

                // console.log("calculate tax");
                // console.log(chargeDate + ": " + (charge + parseFloat(addonForCalculation[chargeDate])));
                // console.log(((charge + parseFloat(addonForCalculation[chargeDate])) * this.state.gst / 100).toFixed(2));
            }
            else {
                totalTaxesCalculated += parseFloat((charge * this.state.gst / 100).toFixed(2));

                // console.log(chargeDate + ": " + charge);
                // console.log((charge * this.state.gst / 100).toFixed(2));
            }

            // console.log(totalTaxesCalculated);
        }
        // console.log(chargeBreakdown);
        // console.log(addonForCalculation);

        totalAddonsTax = parseFloat((totalTaxesCalculated - this.state.tax).toFixed(2));

        // console.log(parseFloat(totalTaxesCalculated.toFixed(2)));

        this.setState({ totalAddonsAmount, totalAddonsTax, summaryAddonsCharges, addonChargeBreakdown: addonCharges });
    }

    loopAddonChargeBreakdown(title) {
        let dataToReturn = [];
        let addonChargeBreakdown = this.state.addonChargeBreakdown;

        if ( addonChargeBreakdown[title] ) {
            for ( let addon in addonChargeBreakdown[title] ) {
                dataToReturn.push(
                    <tr key={ title + addon }>
                        <td width="60%">{ addon } { addonChargeBreakdown[title][addon].type == config.ADDON_TYPE_DAILY ? " x " +  addonChargeBreakdown[title][addon].qty : null }</td>
                        <td width="40%" className="text-right">S$ { (addonChargeBreakdown[title][addon].totalAmount).toFixed(2) }</td>
                    </tr>
                );
            }
        }

        return dataToReturn;
    }

    loopAddons() {
        let dataToReturn = [];

        // BBQ Pit is not according to total rooms at the moment
        let index = 0;
        for ( let index = 0; index < this.state.addons.length; index++ ) {
            let addon = this.state.addons[index];

            dataToReturn.push(
              <div key={index} className="addon-single">
                  {/*<div className="addon-title-wrapper">
                      <h2>Room {index + 1}</h2>
                      <p onClick={()=>this.handleShowHide('data-toggle-2')} className="toggle-detail" data-toggle-id="2">
                          <span className="text">{this.state['data-toggle-2'] ? 'Show' : 'Hide'} Details</span> <em className="data-toggle-2-icon"><FontAwesomeIcon icon="chevron-up"/></em>
                      </p>
                  </div>*/}
                  <div className="addon-detail data-toggle-2" data-toggle-hide="2">
                      <div className="content-left">
                          <h2>{ addon.title }</h2>
                          <div className="img-holder img-left show-mobile">
                              <img className="img-fluid" src={ addon.image } alt={ addon.title } />
                          </div>

                          <div className="addon-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addon.description.replace(/<p>/g, '<small>').replace(/<\/p>/g, '</small>')) }}></div>

  						            <div className="mt-4">
  	                        <table className="table-add-on" cellSpacing="0" cellPadding="0" border="0">
  	                            <tbody>
  	                                <tr>
  	                                    <th></th>
  	                                    <th>Date</th>
                                        <th>No. of Room</th>
                                        <th>Qty / Room</th>
  	                                    <th>Total Amount</th>
  	                                </tr>
  	                                { this.loopAddonDetails(addon, index + 1) }
  	                            </tbody>
  	                        </table>
  						            </div>


                      </div>
                      <div className="img-holder hide-mobile">
                          <img className="img-fluid" src={addon.image} alt={addon.title} />
                      </div>
                  </div>
              </div>
            );
        }

        return dataToReturn;
    }

    handleAddonDaily = addon => event => {
        this.processAddonDaily(addon, event.target.value, event.target.checked);
    }

    processAddonDaily(addon, selectedValue, isChecked) {
        const value = selectedValue + "_" + this.state.addonQty[selectedValue];

        // 1: title, 2: date
        selectedValue = selectedValue.split("_");
        let addonDate = selectedValue[2];

        let addonPackage = addon.packages;

        let amount = parseFloat(addonPackage[addonDate].amount);
        let taxAmount = parseFloat(addonPackage[addonDate].taxAmount);
        let selectedAddons = this.state.selectedAddons;
        let addonQty = this.state.addonQty;

        if ( isChecked ) {
            selectedAddons.push(value);
        }
        else {
            selectedAddons.splice(selectedAddons.indexOf(value), 1);
        }

        this.setState({ selectedAddons });

        this.setAddOnChargeBreakdown(selectedAddons);
    }

    handleAddonStayPeriod = addon => event => {
        this.processAddonStayPeriod(addon, event.target.value, event.target.checked);
    }

    processAddonStayPeriod(addon, selectedValue, isChecked) {
        const value = selectedValue + "_" + this.state.addonQty[selectedValue];
        // 1: title
        selectedValue = selectedValue.split("_");

        let totalAmount = 0;
        let totalTax = 0;
        for ( let index in addon.packages ) {
            totalAmount += parseFloat(addon.packages[index].amount);
            totalTax += parseFloat(addon.packages[index].taxAmount);
        }

        let selectedAddons = this.state.selectedAddons;

        if ( isChecked ) {
            selectedAddons.push(value);

            /*summaryAddonsCharges[selectedValue[0]].amount += (totalAmount);
            summaryAddonsCharges[selectedValue[0]].tax += (totalTax);

            this.setState({ totalAddonsAmount: this.state.totalAddonsAmount + (totalAmount),
                totalAddonsTax: this.state.totalAddonsTax + (totalTax) });*/
        }
        else {
            selectedAddons.splice(selectedAddons.indexOf(value), 1);

            /*summaryAddonsCharges[selectedValue[0]].amount -= (totalAmount);
            summaryAddonsCharges[selectedValue[0]].tax -= (totalTax);

            this.setState({ totalAddonsAmount: this.state.totalAddonsAmount - (totalAmount),
                totalAddonsTax: this.state.totalAddonsTax - (totalTax) });*/
        }

        this.setState({ selectedAddons });

        this.setAddOnChargeBreakdown(selectedAddons);
    }

    handleAddonQty = (addon, addonDate) => event => {
        // If the addon is selected, need to update the details
        /*let selectedAddons = this.state.selectedAddons;
        for ( let index = 0; index < selectedAddons.length; index++ ) {
            // 0: id, 1: title, 2: date

            let selectedAddon = selectedAddons[index];
            let selectedAddonData = selectedAddon.split("_");

            if ( selectedAddonData[0] == addon.id && selectedAddonData[1] == addon.title && selectedAddonData[2] == addonDate ) {
                selectedAddons.splice(index, 1);

                if ( addon.type == config.ADDON_TYPE_DAILY ) {
                    selectedAddons.push(addon.id + "_" + addon.title + '_' + addonDate + "_" + event.target.value);
                }
                else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
                    selectedAddons.push(addon.id + "_" + addon.title + "_" + event.target.value);
                }
            }
        }*/

        //this.setState({ selectedAddons, addonQty, isLoading: true });
        this.setState({ isLoading: true });

        const ROOT_URL = `${config.API_URL}`;
        const thisAddon = addon;
        const addonID = addon.id;
        const apiAddonDate = addonDate;
        const qty = event.target.value;

        axios.post(`${ROOT_URL}/${apis.API_GET_ADDON_PRICE}`, _createFormData({
      			startDate: apiAddonDate,
      			endDate: apiAddonDate,
      			numberOfRooms: this.state.totalRooms,
      			adult: this.state.totalAdult,
      			children: this.state.totalChildren,
            addonID: addonID,
            qty: qty
      	}))
        .then(response => {
            // Reset addons data based on the returned data
            let addons = this.state.addons;
            let selectedAddons = this.state.selectedAddons;
            let addonQty = this.state.addonQty;
            let isSet = false;

            for ( let index = 0; index < addons.length && !isSet; index++ ) {
                if ( addons[index].id == addonID ) {
                    addons[index].packages[apiAddonDate] = response.data.addon[apiAddonDate];

                    this.setState({ addons });
                    isSet = true;
                }
            }

            // Update the selected qty. calculate the pricing later after getting the pricing from backend
            if ( addon.type == config.ADDON_TYPE_DAILY ) {
                addonQty[thisAddon.id + "_" + thisAddon.title + '_' + addonDate] = parseInt(qty);
            }
            else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
                addonQty[thisAddon.id + "_" + thisAddon.title] = parseInt(qty);
            }

            // If the addon is selected, need to update the details
            for ( let index = 0; index < selectedAddons.length; index++ ) {
                // 0: id, 1: title, 2: date
                let selectedAddon = selectedAddons[index];
                let selectedAddonData = selectedAddon.split("_");

                if ( selectedAddonData[0] == addon.id && selectedAddonData[1] == addon.title && selectedAddonData[2] == addonDate ) {
                    selectedAddons.splice(index, 1);

                    if ( addon.type == config.ADDON_TYPE_DAILY ) {
                        selectedAddons.push(addon.id + "_" + addon.title + '_' + addonDate + "_" + qty);
                    }
                    else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
                        selectedAddons.push(addon.id + "_" + addon.title + "_" + qty);
                    }
                }
            }

            this.setState({ addonQty, selectedAddons, isLoading: false });

            this.setAddOnChargeBreakdown(selectedAddons, addons);
      	});
    }

  	loopAddonDetails(addon, index) {
    		let dataToReturn = [];

        if ( addon.type == config.ADDON_TYPE_DAILY ) {
            for ( let addonDate in addon.packages ) {
                if ( addon.packages[addonDate].qty > 0 ) {
                    // set key for selected addons; need to combine the qty
                    let selectedAddonKey = addon.id + "_" + addon.title + "_" + addonDate;
                    selectedAddonKey += "_" + this.state.addonQty[selectedAddonKey];

                    let addonQtyKey = addon.id + "_" + addon.title + "_" + addonDate;

                    let qty = this.state.addonQty[addonQtyKey] ? this.state.addonQty[addonQtyKey] : 1;

                    dataToReturn.push(
                      <tr key={ addon.title + addonDate }>
                          <td>
                              { addon.is_mandatory ?
                                <input name={addon.title + addonDate + index} checked={ true } readOnly={ true }
                                    type="checkbox" value={ addon.id + "_" + addon.title + '_' + addonDate } id={ addonDate + index } />
                              :
                                <input name={addon.title + addonDate + index} checked={ this.state.selectedAddons.indexOf(selectedAddonKey) > -1 }
                                  type="checkbox" value={ addon.id + "_" + addon.title + '_' + addonDate } id={ addonDate + index } onChange={ this.handleAddonDaily(addon, index) } />
                              }
                              <span className="checkmark"></span>
                          </td>
                          <td>
                              {addonDate}
                          </td>
                          <td className="text-center">
                              {this.state.totalRooms}
                          </td>
                          <td className="text-center">
                            { addon.allow_multiple_purchase ?
                              <select name="addonQty" defaultValue={ qty } onChange={ this.handleAddonQty(addon, addonDate) }>
                                { this.loopQty(addon, addonDate, addon.packages[addonDate].qty) }
                              </select>
                            :
                              1
                            }
                          </td>
                          <td>
                              S${ parseFloat(addon.packages[addonDate].amount).toFixed(2) }
                          </td>
                      </tr>
                    );
                }
                else {
                  dataToReturn.push(
                    <tr key={ addonDate }>
                        <td>
                            &nbsp;
                        </td>
                        <td>
                            {addonDate}
                        </td>
                        <td className="text-center">
                            {this.state.totalRooms}
                        </td>
                        <td className="text-center">1</td>
                        <td>
                            <strong>Sold Out</strong>
                        </td>
                    </tr>
                  );
                }
            }
        }
        else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
            let totalAmount = 0;
            let dates = _formatDate(this.state.checkInDate) + "<br />to<br />" + _formatDate(this.state.checkOutDate);
            for ( let addonDate in addon.packages ) {
                totalAmount += parseFloat(addon.packages[addonDate].amount);
            }

            // set key for selected addons; need to combine the qty
            let selectedAddonKey = addon.id + "_" + addon.title;
            selectedAddonKey += "_" + this.state.addonQty[selectedAddonKey];

            dataToReturn.push(
              <tr key={ addon.title  }>
                  <td>
                      { addon.is_mandatory ?
                        <input name={addon.title + index} checked={ true } readOnly={ true }
                            type="checkbox" value={ addon.id + "_" + addon.title } id={ addon.title + index } />
                      :
                        <input name={addon.title + index} checked={ this.state.selectedAddons.indexOf(selectedAddonKey) > -1 }
                          type="checkbox" value={ addon.id + "_" + addon.title } id={ addon.title + index } onChange={ this.handleAddonStayPeriod(addon, index) } />
                      }
                      <span className="checkmark"></span>
                  </td>
                  <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(dates) }}>
                  </td>
                  <td className="text-center">
                      {this.state.totalRooms}
                  </td>
                  <td className="text-center">1</td>
                  <td>
                      S${ (totalAmount).toFixed(2) }
                  </td>
              </tr>
            );
        }

    		return dataToReturn;
  	}

    loopQty(addon, addonDate, addonQty) {
      let dataToReturn = [];

      let selectedAddonQty = 1;
      if ( addon.type == config.ADDON_TYPE_DAILY ) {
          selectedAddonQty = this.state.addonQty[addon.id + "_" + addon.title + "_" + addonDate];
      }
      else if ( addon.type == config.ADDON_TYPE_STAY_PERIOD ) {
          selectedAddonQty = this.state.addonQty[addon.id + "_" + addon.title];
      }

      for ( let index = addon.min_qty; index <= addon.max_qty && index <= addonQty; index++ ) {
          if ( selectedAddonQty == index ) {
              dataToReturn.push(
                  <option key={ addon.title + "qty" + index } value={ index }>
                      { index }
                  </option>
              );
          }
          else {
              dataToReturn.push(
                  <option key={ addon.title + "qty" + index } value={ index }>
                      { index }
                  </option>
              );
          }
      }

      return dataToReturn;
    }

    render(){
        if ( this.state.isLoading ) { _loading(); }

        if ( this.state.totalRateAndPackages === null || this.state.totalTaxesAndFees === null || this.state.grandTotal === null ) { return null; }

        return(
            <div>
                { ( this.state.isLoading ) ? _loading() : null }

                <div id="book-step" className="no-banner">
                      <div className="container">
                          <div className="row">
                              <div id="sort">
                                  <div className="btn-group position-relative" id="sort-list">
                                      &nbsp;
                                  </div>
                              </div>

                              <div className="step next" data-id="1">
                                  <span style={{paddingTop:2}} className="number-count">1</span>Select Room & Dates
                              </div>

                              <div className="step active" data-id="2">
                                  <span style={{paddingTop:2}} className="number-count">2</span>Select Add-Ons
                              </div>

                              <div className="step next" data-id="3">
                                  <span style={{paddingTop:1}} className="number-count">3</span>Guest Information & Payment
                              </div>
                              <div className="step next" data-id="4">
                                  <span style={{paddingTop:1}} className="number-count">4</span>Confirmation
                              </div>

                              <div id="sort">
                                  <div className="btn-group position-relative" id="sort-list">
                                      &nbsp;
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  { (this.state.updateToken !== null && this.state.modifyBookingID !== null) ?
                    <div className="container maintenance">
                      <div className="row">
                        <div className="col-12 alert alert-warning mt-4">
                            You're currently modifying your booking. The Reservation ID is { this.state.modifyBookingID }.
                            <br />
                            <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={ this.handleCancelModifying.bind(this) }>Cancel</a>
                        </div>
                      </div>
                    </div>
                    : null }

                  {isMobileView() ?
                  (
                      <div className="container" id="textStep">
                          <div className="row">
                              <p>Select Add-Ons</p>
                          </div>
                      </div>
                  ) :
                  null
                  }
                <section id="total-reservation" className="show-mobile">
                    <div className="container">
                        <div className="row reservation">
                            <div className="col-6 left">
                                <p className="grand-total">{/*<span className="count">3x</span> | */}Grand Total: <span className="price">S$ { _formatDecimalAmount(this.state.grandTotal + this.state.totalAddonsAmount + this.state.totalAddonsTax) }</span></p>
                                <p className="link" data-toggle="modal" data-target="#reservation-modal">View Summary</p>
                            </div>
                            <div className="col-6 right">
                                <p onClick={ this.next }>NEXT</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="main" className="section-addon">


                    <div className="container">
                        <div className="row">

                            <div className="col-md-8" id="addons">
                                <NavLink to="/" onClick={this.handleModifyBack.bind(this)} className="link-back">&lt; Back</NavLink>

                                { this.loopAddons() }

                            </div>

                            <div className="col-md-4" id="sidebar">
                                {this.setUpReservationPart()}
                            </div>

                        </div>

                    </div>

                </section>
            </div>
        )
    }
}
export default Main;
